import {
  AppPlayLinksProps,
  LoginFormCopy,
} from "@bluebottlecoffee/design-system/components";
import { GiftCardFormCopy } from "@bluebottlecoffee/design-system/components/GiftCard/GiftCardForm";
import {
  Subscription,
  SubscriptionType,
} from "@bluebottlecoffee/design-system/components/lib/types";
import { ConsentManagerCopyProps } from "../../components/ConsentManagerWrapper";
import { filters, keyof } from "./utils";
import { SearchDialogCopy } from "../../components/SearchDialog";

import {
  AnnouncementBar,
  CafesPage,
  Cart,
  CookiesNotice,
  EmailOptIn,
  Footer,
  GiftCardRedemption,
  GiftSubRedemption,
  Nav,
  NavAndCartInfoBanner,
  ProductRec,
  ShippingCopy,
  ShopCollection,
} from "../sanity-schema";
import {
  buildTranslationGroupQuery,
  TranslationGroupSanityType,
} from "./translation-group-queries";
import { shopCardDereferencingQuery } from "./shop-card-queries";
import { SourceProductRec } from "../transformers/product-recs";
import { SourceShopCollectionProduct } from "../algolia/types";
import {
  DereferencedSubscriptionOption,
  forSaleDereferencedVariants,
  optionTypesProjection,
  productProjectionSubs,
  shippingCopyQuery,
  subscriptionOptionsProjection,
  subscriptionProjection,
} from "./product-queries";
import { subscribableProductFilter } from "./subscribable-products-queries";
import { FlavorProfileCopyTranslations } from "../transformers/flavor-profile";
import { DereferencedVariant } from "./variant-queries";
import { AccountMenuCopy } from "../account-menu";

export enum SharedQueryKey {
  ACCOUNT_MENU = "accountMenuCopy",
  ANNOUNCEMENT_BAR = "announcementBar",
  APP_PLAY_LINKS = "appPlayLinks",
  ARIA = "aria",
  CAFES = "cafesPage",
  CART = "cart",
  CONSENT_MANAGER = "consentManagerCopy",
  COOKIES_NOTICE = "cookiesNotice",
  EMAIL_OPT_IN = "emailOptIn",
  FOOTER = "footer",
  FLAVOR_PROFILE_COPY = "flavorProfileCopy",
  GIFT_CARD_FORM_COPY = "giftCardFormCopy",
  GIFT_CARD_REDEMPTION = "giftCardRedemption",
  GIFT_SUB_REDEMPTION = "giftSubRedemption",
  LOGIN_DIALOG = "loginDialogCopy",
  NAV = "nav",
  NAV_AND_CART_INFO_BANNER = "navAndCartInfoBanner",
  PRODUCT_RECS = "productRecs",
  PRODUCT_SEARCH = "productSearch",
  SUBSCRIBABLE_PRODUCTS = "subscribableProducts",
}

export type SubscribableProductsSchema = {
  subscription: Subscription;
  subscriptionOptions: DereferencedSubscriptionOption[];
  subscriptionType: SubscriptionType;
  variants: DereferencedVariant[];
};

export interface PageLink {
  name: string;
  slug: string;
}

export interface BaseSanityQueryType {
  [SharedQueryKey.ACCOUNT_MENU]: TranslationGroupSanityType;
  [SharedQueryKey.ANNOUNCEMENT_BAR]: AnnouncementBar;
  [SharedQueryKey.APP_PLAY_LINKS]: TranslationGroupSanityType;
  [SharedQueryKey.ARIA]: TranslationGroupSanityType;
  [SharedQueryKey.CONSENT_MANAGER]: TranslationGroupSanityType;
  [SharedQueryKey.COOKIES_NOTICE]: CookiesNotice;
  [SharedQueryKey.EMAIL_OPT_IN]: EmailOptIn;
  [SharedQueryKey.FLAVOR_PROFILE_COPY]: TranslationGroupSanityType;
  [SharedQueryKey.FOOTER]: Footer;
  [SharedQueryKey.GIFT_CARD_FORM_COPY]: TranslationGroupSanityType;
  [SharedQueryKey.GIFT_CARD_REDEMPTION]: GiftCardRedemption;
  [SharedQueryKey.GIFT_SUB_REDEMPTION]: GiftSubRedemption;
  [SharedQueryKey.LOGIN_DIALOG]: TranslationGroupSanityType;
  [SharedQueryKey.NAV]: Nav;
  [SharedQueryKey.NAV_AND_CART_INFO_BANNER]: NavAndCartInfoBanner;
  [SharedQueryKey.PRODUCT_RECS]: Omit<ProductRec, "products"> & {
    products: SourceProductRec[];
  };
  [SharedQueryKey.PRODUCT_SEARCH]: {
    productSearchCopy: TranslationGroupSanityType;
    shopcardCopy: {
      shippingCopy: ShippingCopy;
      conversionCopy: TranslationGroupSanityType;
    };
  };
}
export interface CafesPageQueryType extends BaseSanityQueryType {
  [SharedQueryKey.CAFES]: CafesPage;
}
export interface CartQueryType extends BaseSanityQueryType {
  [SharedQueryKey.CART]: Cart;
  [SharedQueryKey.SUBSCRIBABLE_PRODUCTS]: SubscribableProductsSchema[];
}

export type AriaTranslations = {
  accountButtonLabel: string;
  backToTopAriaLabel: string;
  brandLinkLabel: string;
  burgerNavMenuButtonLabel: string;
  cartLinkLabel: string;
  dismissNavMenuButtonLabel: string;
  liveRegionLoadingText: string;
  productSearchButtonLabel: string;
  skipToContent: string;
  skipToCookieNotice: string;
  cartNavMenuButtonLabel: string;
  oneColumnButton: string;
  twoColumnsButton: string;
  backButtonAccount: string;
  playPauseAriaLabel: string;
};

export interface BaseProps
  extends Omit<
    BaseSanityQueryType,
    // translation groups are transformed from their query type to data type
    | SharedQueryKey.ACCOUNT_MENU
    | SharedQueryKey.APP_PLAY_LINKS
    | SharedQueryKey.ARIA
    | SharedQueryKey.CONSENT_MANAGER
    | SharedQueryKey.GIFT_CARD_FORM_COPY
    | SharedQueryKey.FLAVOR_PROFILE_COPY
    | SharedQueryKey.LOGIN_DIALOG
    | SharedQueryKey.PRODUCT_RECS
    | SharedQueryKey.PRODUCT_SEARCH
  > {
  region: string;
  lang: string;
  [SharedQueryKey.ACCOUNT_MENU]: AccountMenuCopy;
  [SharedQueryKey.APP_PLAY_LINKS]: Omit<AppPlayLinksProps, "bgColor">;
  [SharedQueryKey.ARIA]: AriaTranslations;
  [SharedQueryKey.CONSENT_MANAGER]: ConsentManagerCopyProps;
  [SharedQueryKey.GIFT_CARD_FORM_COPY]: GiftCardFormCopy;
  [SharedQueryKey.FLAVOR_PROFILE_COPY]: FlavorProfileCopyTranslations;
  [SharedQueryKey.LOGIN_DIALOG]: LoginFormCopy;
  [SharedQueryKey.PRODUCT_RECS]: SourceProductRec[];
  [SharedQueryKey.PRODUCT_SEARCH]: SearchDialogCopy;
}

export interface BasePropsWithSlug extends BaseProps {
  slug: string;
}

export interface CafesPageBaseProps extends BaseProps {
  [SharedQueryKey.CAFES]: CafesPage;
}

export interface CartBaseProps extends BaseProps {
  [SharedQueryKey.CART]: Cart;
  [SharedQueryKey.SUBSCRIBABLE_PRODUCTS]: SubscribableProductsSchema[];
}

export interface CartBasePropsWithSlug
  extends CartBaseProps,
    BasePropsWithSlug {}

const announcementBarQuery: string = `*[
  ${filters.whereType("announcementBar")} &&
  ${filters.excludeDrafts}
][0]`;

const cafesPageQuery: string = `*[
  ${filters.whereType("cafesPage")} &&
  ${filters.excludeDrafts}
][0]{
  ...,
  modules[]{
    ...,
    select(_type == "cafeLocator") => {
      ...,
      previewCafes[]->
    }
  }
}`;

const cartQuery: string = `*[
  ${filters.whereType("cart")} &&
  ${filters.excludeDrafts}
][0]`;

const cookiesNoticeQuery: string = `*[
  ${filters.whereType("cookiesNotice")} &&
  ${filters.excludeDrafts}
][0]`;

const footerQuery: string = `*[
  ${filters.whereType("footer")} &&
  ${filters.excludeDrafts}
][0]`;

const navQuery: string = `*[
  ${filters.whereType("nav")} &&
  ${filters.excludeDrafts}
][0]`;

const navAndCartInfoBannerQuery: string = `*[
  ${filters.whereType("navAndCartInfoBanner")} &&
  ${filters.excludeDrafts}
][0]`;

const emailOptInQuery: string = `*[
  ${filters.whereType("emailOptIn")} &&
  ${filters.excludeDrafts}
][0]`;

const giftCardRedemptionQuery: string = `*[
  ${filters.whereType("giftCardRedemption")} &&
  ${filters.excludeDrafts}
][0]`;

const giftSubRedemptionQuery: string = `*[
  ${filters.whereType("giftSubRedemption")} &&
  ${filters.excludeDrafts}
][0]`;

const productSearch = (lang: string) => `{
  "productSearchCopy": ${buildTranslationGroupQuery(lang, "ProductSearch")},
  "shopcardCopy": {
    "shippingCopy": ${shippingCopyQuery},
    "conversionCopy": ${buildTranslationGroupQuery(lang, "Conversion")},
  }
}`;

export const productRecsQuery = (lang: string) => `*[
  ${filters.whereType("productRec")}
  && ${filters.excludeDrafts}
]{
  products[]->{
    ...
    , ${keyof<SourceProductRec>("optionTypes")}[]->${optionTypesProjection}
    , ${forSaleDereferencedVariants<SourceProductRec>("variants")}
    , ${keyof<SourceProductRec>("shopCollections")}[]->
    , ${keyof<SourceProductRec>("subscription")}->${subscriptionProjection}
    , ${keyof<SourceProductRec>(
      "subscriptionOptions",
    )}[]${subscriptionOptionsProjection(lang)}
    , "${keyof<SourceShopCollectionProduct>(
      "primaryShopCollectionName",
    )}": ${keyof<SourceProductRec>(
      "shopCollections",
    )}[][0]->${keyof<ShopCollection>("name")}
    , ${shopCardDereferencingQuery}
  }
}[0]`;

/** All the shared Sanity queries */
export const queries = {
  announcementBarQuery,
  cookiesNoticeQuery,
  emailOptInQuery,
  footerQuery,
  giftCardRedemptionQuery,
  giftSubRedemptionQuery,
  navQuery,
  navAndCartInfoBannerQuery,
  productRecsQuery,
  productSearch,
};

export const cafesPageQueries = {
  ...queries,
  cafesPageQuery,
};

export const cartQueries = {
  ...queries,
  cartQuery,
};

const sharedQueries = (lang: string): string => `
  "${SharedQueryKey.ACCOUNT_MENU}": ${buildTranslationGroupQuery(
    lang,
    "AccountMenu",
  )},
  "${SharedQueryKey.ANNOUNCEMENT_BAR}": ${queries.announcementBarQuery},
  "${SharedQueryKey.APP_PLAY_LINKS}": ${buildTranslationGroupQuery(
    lang,
    "AppPlayLinks",
  )},
  "${SharedQueryKey.ARIA}": ${buildTranslationGroupQuery(lang, "Aria")},
  "${SharedQueryKey.CONSENT_MANAGER}": ${buildTranslationGroupQuery(
    lang,
    "SegmentConsentManager",
  )},
  "${SharedQueryKey.COOKIES_NOTICE}": ${queries.cookiesNoticeQuery},
  "${SharedQueryKey.EMAIL_OPT_IN}": ${queries.emailOptInQuery},
  "${SharedQueryKey.FOOTER}": ${queries.footerQuery},
  "${SharedQueryKey.GIFT_CARD_REDEMPTION}": ${queries.giftCardRedemptionQuery},
  "${SharedQueryKey.GIFT_SUB_REDEMPTION}": ${queries.giftSubRedemptionQuery},
  "${SharedQueryKey.FLAVOR_PROFILE_COPY}": ${buildTranslationGroupQuery(
    lang,
    "FlavorProfileCopy",
  )},
  "${SharedQueryKey.LOGIN_DIALOG}": ${buildTranslationGroupQuery(
    lang,
    "LoginDialog",
  )},
  "${SharedQueryKey.NAV}": ${queries.navQuery},
  "${SharedQueryKey.NAV_AND_CART_INFO_BANNER}": ${
    queries.navAndCartInfoBannerQuery
  },
  "${SharedQueryKey.PRODUCT_RECS}": ${queries.productRecsQuery(lang)},
  "${SharedQueryKey.PRODUCT_SEARCH}": ${queries.productSearch(lang)},
  "${
    SharedQueryKey.SUBSCRIBABLE_PRODUCTS
  }": ${subscribableProductFilter}${productProjectionSubs(lang)}

`;
// NOTE: The query above MUST NOT END WITH A TRAILING COMMA!

export const sharedSanityQuery = (lang: string): string => `{
  ${sharedQueries(lang)}
}`;

export const sharedSanityCafesPageQuery = (lang: string): string => `{
  ${sharedQueries(lang)},
  "${SharedQueryKey.CAFES}": ${cafesPageQueries.cafesPageQuery}
}`;

export const sharedSanityCartQuery = (lang: string): string => `{
  ${sharedQueries(lang)},
  "${SharedQueryKey.CART}": ${cartQueries.cartQuery},
  "${SharedQueryKey.GIFT_CARD_FORM_COPY}": ${buildTranslationGroupQuery(
    lang,
    "GiftCardFormCopy",
  )}
}`;
